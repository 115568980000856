import { useEffect } from 'react';

import { useNavigate } from '@hooks/useNavigate';

const InNeedPage = () => {
  const navigate = useNavigate();
  /**
   * Just for backward compability with old site
   *
   * TODO: make normal redirect using other ways
   */

  useEffect(() => {
    navigate(`/cabinet/customer`);
  }, []);

  return null;
};

export default InNeedPage;
